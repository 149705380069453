<template lang="pug">
    div
        div.login-container
            login-form
</template>

<script lang="ts">
import Vue from 'vue';
import LoginForm from 'skillbase-component/src/components/skillbase-oauth2-server/LoginForm.vue'

export default Vue.extend({
    components: {
        LoginForm
    }
})
</script>

<style lang="scss">
.login-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    background-image: url('/img/background-login.png');
    background-repeat: no-repeat;
    background-size: auto;
}
</style>